import React from 'react';
import '../../styles/index.css';
import './ContactPage.css';

import { Form, Button, Alert } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";


class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,

            // form field values
            name: '',
            email: '',
            phone: '',
            preferredContactMethod: '',
            message: '',

            // error flags
            nameError: false,
            emailError: false,
            phoneError: false,
            preferredContactMethodError: false,
            messageError: false,

            // reCaptcha is valid
            reCaptchaIsValid: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.isValid = this.isValid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.handleReCaptchaChange = this.handleReCaptchaChange.bind(this);
    }

    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.id;

        this.setState({
            [name]: value
        });
    }

    handleBlur(event) {
        const field = event.target.id;
        const value = event.target.value;

        this.isValid(field, value);
    }

    isValid(field, value) {
        var valid = false;
        switch (field) {
            case "name":
                valid = (value.length > 0) ? true : false;
                break;
            case "email":
                var emailRegex = /^.+@.+\..+$/;
                if (value.length > 0) {
                    // if there is text, match it to regex
                    valid = (emailRegex.test(value)) ? true : false;
                }
                break;
            case "phone":
                var phoneRegex = /^\(?([0-9]{3})\)?[-, ]?([0-9]{3})[-, ]?([0-9]{4})$/;
                if (value.length > 0) {
                    // if there is text, match it to regex
                    valid = (phoneRegex.test(value)) ? true : false;
                }
                break;
            case "preferredContactMethod":
                valid = (this.state.preferredContactMethod.length > 0) ? true : false;
                break;
            case "message":
                valid = (value.length > 0) ? true : false;
                break;
            default:
                valid = false;
        }

        this.setState({
            [field + "Error"]: !valid
        });

        return valid;
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.state.submitted) {
            console.log("Form was already submitted.");
            return;
        }

        if (this.formIsValid()) {
            console.log("Form is valid for submission.");
            this.setState({
                submitted: true
            });

            var template_params = {
                "submissionDateTime": Date().toLocaleString(),
                "name": this.state.name,
                "email": this.state.email,
                "phone": this.state.phone,
                "preferredContactMethod": this.state.preferredContactMethod,
                "message": this.state.message
            }

            console.log("Sending contact form.");

            var service_id = "default_service";
            var template_id = "quirky_owl_contact_us";
            console.log(window.emailjs.send(service_id, template_id, template_params));
        }

    }

    formIsValid() {
        var valid = true;

        valid = this.isValid("name", this.state.name) && valid;
        valid = this.isValid("email", this.state.email) && valid;
        valid = this.isValid("phone", this.state.phone) && valid;
        valid = this.isValid("preferredContactMethod", this.state.preferredContactMethod) && valid;
        valid = this.isValid("message", this.state.message) && valid;
        valid = this.state.reCaptchaIsValid && valid;

        return valid;
    }

    handleReCaptchaChange(value) {
        if (value != null) {
            this.setState({
                reCaptchaIsValid: true
            });
        } else {
            this.setState({
                reCaptchaIsValid: false
            });
        }
    }


    render() {
        return (
            <div className="body">
                {/* Todo - update once home page is done */}
                <h1>Contact Us</h1>
                <p>Welcome to Quirky Owl Framing! Our website is currently being developed. To contact us for more information or with questions, please complete the form below and we will be in contact with you soon.</p>
                {/* todo - update this spacing to not use br tags */}
                <br />

                <Form noValidate onSubmit={this.handleSubmit} hidden={this.state.submitted}>
                    <Form.Group controlId="name">
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control
                            required
                            as="input"
                            type="text"
                            placeholder="Name"
                            onChange={this.handleInputChange}
                            onBlur={this.handleBlur}
                            isInvalid={this.state.nameError} />
                        <Form.Control.Feedback type="invalid">
                            Name is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="email">
                        <Form.Label>
                            Email
                        </Form.Label>
                        <Form.Control
                            as="input"
                            type="email"
                            placeholder="Email"
                            onChange={this.handleInputChange}
                            onBlur={this.handleBlur}
                            isInvalid={this.state.emailError} />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid email address.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="phone">
                        <Form.Label>
                            Phone Number
                        </Form.Label>
                        <Form.Control
                            as="input"
                            type="tel"
                            placeholder="xxx-xxx-xxxx"
                            onChange={this.handleInputChange}
                            onBlur={this.handleBlur}
                            isInvalid={this.state.phoneError} />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid 10 digit phone number.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <fieldset>
                        <Form.Group onChange={this.handleInputChange}>
                            <Form.Label as="legend" style={{ 'fontSize': 'inherit' }}>
                                Preferred Contact Method
                            </Form.Label>
                            <Form.Check
                                required
                                type="radio"
                                label="Email"
                                name="preferredContactMethod"
                                id="preferredContactMethod"
                                value="email"
                                onChange={this.handleInputChange}
                                onInput={this.handleBlur}
                                isInvalid={this.state.preferredContactMethodError}
                                feedback="Please provide a preferred contact method."
                            />
                            <Form.Check
                                type="radio"
                                label="Call"
                                name="preferredContactMethod"
                                id="preferredContactMethod"
                                value="call"
                                onChange={this.handleInputChange}
                                onInput={this.handleBlur}
                                isInvalid={this.state.preferredContactMethodError} />
                            <Form.Check
                                type="radio"
                                label="Text"
                                name="preferredContactMethod"
                                id="preferredContactMethod"
                                value="text"
                                onChange={this.handleInputChange}
                                onInput={this.handleBlur}
                                isInvalid={this.state.preferredContactMethodError} />
                            <Form.Control.Feedback type="invalid">
                                You must provide a preferred contact method.
                        </Form.Control.Feedback>
                        </Form.Group>
                    </fieldset>

                    <Form.Group controlId="message">
                        <Form.Label>
                            Message
                        </Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            placeholder=""
                            onChange={this.handleInputChange}
                            onBlur={this.handleBlur}
                            isInvalid={this.state.messageError} />
                        <Form.Control.Feedback type="invalid">
                            Please enter a message.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <ReCAPTCHA
                        sitekey="6LfGtMwZAAAAAFqzNdUMsRskVTmzy1SC-SXE9t-1"
                        onChange={this.handleReCaptchaChange}
                    />
                    <br />
                    <Form.Group>
                        <Button type="submit">Submit</Button>
                    </Form.Group>
                </Form>
                <Alert show={this.state.submitted} variant="success" transition={null}>Your message has been sent. Thank you!</Alert>
            </div >
        );
    }
}

export default ContactPage;
