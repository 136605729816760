import React from 'react';

function PageNotFound() {
    return (
        <div>
            Page Not Found
        </div>
    );
}

export default PageNotFound;
