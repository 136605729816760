import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import HomePage from './HomePage';
import ContactPage from './ContactPage/ContactPage';
import PageNotFound from './PageNotFound';
import Navigation from './Navigation/Navigation';

function App() {
  return (
    <div>

      <Navigation />

      <div id="content">
        <Switch>
          {/* Update root to HomePage once page is complete */}
          <Route path="/" exact component={ContactPage} />
          <Route path="/contact" component={ContactPage} />
          <Route component={PageNotFound} />
        </Switch>
      </div>

    </div>
  );
}

export default App;
