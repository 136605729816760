import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Navigation.css';

function Navigation() {
    return (
        // variant light for light backgrounds and dark for dark backgrounds
        // expand says all breakpoints below the specified will have horizontal padding removed

        <Navbar bg="light" variant="light" expand="lg" fixed="top" className="shadow">

            <div className="henny-penny-font">
                <a href="/" className="navbar-brand" style={{ 'fontSize': '2.5em' }}>
                    <img
                        alt="Quirky Owl logo"
                        src={require('../../images/logo_navbar.png')}
                        width="46"
                        height="60"
                        className="d-inline-block align-top"
                    />{' '}
                    <span id="desktopLogo">Quirky Owl Custom Picture Framing</span>
                    <span id="tabletLogo">Quirky Owl</span>
                </a>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigationLinks" aria-controls="navigationLinks" aria-expanded="false" aria-label="Toggle navigation links">
                <span className="navbar-toggler-icon"></span>
            </button>
            <Nav className="ml-auto collapse navbar-collapse" id="navigationLinks">
                {/* <Nav.Link href="/">Home</Nav.Link> */}
                <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
        </Navbar >

    );
}

export default Navigation;
